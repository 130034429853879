// 基于 axios 封装的网络请求的函数
import axios from 'axios'
import store from '@/store'
// .js文件无法通过this.$router使用,
import router from '@/router'
// js文件无法通过this.$message使用
import { Message } from 'element-ui'

// 黑马的服务器
// export const baseURL = 'http://big-event-vue-api-t.itheima.net'
// 本地的服务器
// export const baseURL = 'http://127.0.0.1:3007'
// 我的阿里的云服务器
export const baseURL = 'http://www.bigeventapiserver.lttiryc.top'

// axios.create() 创建一个带配置项的自定义 axios 函数
// myAxios 请求的时候, 在请求地址 之前 拼接 baseURL ，然后去请求后台
const myAxios = axios.create({
  // 基地址
  baseURL
})

// 定义请求拦截器
// api 里每次调用 request 都会先走这个请求拦截器
// 第一个参数函数在请求前会触发一次，第一个参数函数请求发起前的代码，如果有异常报错，会直接进入这里
myAxios.interceptors.request.use((config) => {
  // console.log(config)
  // 为请求头挂载 Authorization 字段
  // 判断，登录页面和注册页面，vuex 里无 token ，而且登录接口和注册接口也不需要携带 token (其他页面需要).
  if (store.state.token) {
    config.headers.Authorization = store.state.token
  }

  // config 配置对象(要请求后台的参数都在这个对象上)
  // 这个 return 交给 axios 源码内，根据配置项发起请求
  // 它返回给 axios 内源码，
  return config
}, (error) => {
  // 返回一个拒绝状态的 Promise 对象( axios 留在原地的 Promise 对象状态就为失败，结果为 error 变量值)
  // 此函数类似 catch 函数()里的 return
  // 口诀: return 的非 Promise 对象的值，会作为成功的结果，返回给下一个Promise对象(axios留在原地)
  // 口诀:returnPromise 对象，这个 Promise 对象状态，返回给下一个 Promise 对象
  // Promise.reject() 原地留下一个新的 Promise 对象(状态为失败)
  return Promise.reject(error)
  /** 等同于
   * return new Promise((resolve,reject) =>{
   *    reject(error)
   * })
   */
})

// 定义响应拦截器
myAxios.interceptors.response.use((response) => {
  // 响应 http 状态码为 2xx 或 3xx 时触发成功的回调，形参中的 response 是“成功的结果”
  // return 到 axios 原地 Promise 对象，作为成功的结果
  return response
}, (error) => {
  // 响应状态码为4xx，5xx时触发失败的回调，形参中的 error 是失败的结果”
  // 去破坏 localStorage 里的值，刷新让 vuex 取出错误的 token，导致401
  if (error.request.status === 401 || error.request.status === 500) {
    // 本次响应是token过期了
    // 清除vuex里一切，然后切换回到登录页面（被动退出登录状态)
    store.commit('updateToken', '')
    store.commit('updateUserInfo', {})
    Message.error('用户身份已过期!')
    router.push('/login')
  }
  // return 到 axios 原地 Promise 对象位置，作为失败拒绝的状态(如果那边用 try+catch 或者 catch 函数捕获，可以捕获到我们传递过去的这个error变量的值)
  return Promise.reject(error)
})

// 导出 axios 自定义函数
export default myAxios
