// 封装的是具体的接口请求方法
// 注意:每个方法只负责请求一个url地址
import request from '@/utils/request' // 引入自定义axios函数

// ES6规定，key 和 value变量名 同名的时候，可以简写(key既为key也是value变量名)
/**
 * 请求-登入接口
 * @param username 用户名
 * @param password 密码
 * @returns {AxiosPromise} Promise对象
 */
const loginAPI = ({username, password}) => {
  // 原地是一个 Promise 对象(内部包含原生ajax请求)
  // return 这个 Promise 对象到逻辑页面，去那边对 Promise 对象提取结果
  return request({
    url: '/api/login',
    method: 'POST',
    // axios 传参 params，data
    // params 的对象参数名和值， axios 源码会把参数和值，拼接在 url? 后面给后台（query 查询字符串）
    // data 的对象参数名和值，axios 源码会把参数和值，拼接在请求体里（body 参数）
    data: {
      username,
      password
    }
  })
}

/**
 * 请求-注册接口
 * @param username
 * @param password
 * @param repassword
 * @returns {AxiosPromise}
 */
const registerAPI = ({username, password, repassword}) => {
  return request({
    url: '/api/reg',
    method: 'POST',
    data: {
      username,
      password,
      repassword
    }
  })
}

/**
 * 获取-用户信息
 * @returns {AxiosPromise}
 */
const getUserInfoAPI = () => {
  return request({
    url: '/my/userinfo'
    // method不写默认就是'get'方式请求
    // 传参给后台: params(查询字符串query), data(请求体body)， headers(请求头)
    /* headers: {
      // this.$store.state.token这里this不是组件对象不能用this.$store拿到store对象
      Authorization: store.state.token
    } */
  })
}

/**
 * 获取-侧边栏的数据
 * @returns {AxiosPromise}
 */
const getMenusListAPI = () => {
  return request({
    url: '/my/menus'
    /* headers: {
      Authorization: store.state.token
    } */
  })
}

/**
 * 更新-用户基本资料
 * @param id
 * @param username
 * @param nickname
 * @param email
 * @param user_pic
 * @returns {*}
 */
const updateUserInfoAPI = ({id, username, nickname, email, user_pic}) => {
  return request({
    url: 'my/userinfo',
    method: 'put',
    data: {
      id,
      username,
      nickname,
      email,
      user_pic
    }
  })
}

/**
 * 更新-用户头像
 * @param avatar 头像base64字符串
 * @returns {*} Promise对象
 */
const updateUserAvatarAPI = (avatar) => {
  return request({
    url: 'my/update/avatar',
    method: 'patch',
    data: {
      avatar // 头像base64字符串
    }
  })
}

/**
 * 更新-用户密码
 * @param old_ped
 * @param new_pwd
 * @param re_pwd
 * @returns {*} Promise对象
 */
const updatePwdAPI = ({old_pwd, new_pwd, re_pwd}) => {
  return request({
    url: 'my/updatepwd',
    method: 'patch',
    data: {
      old_pwd,
      new_pwd,
      re_pwd
    }
  })
}

/**
 * 获取-文章分类
 * @returns {*}
 */
const getArtCateListAPI = () => {
  return request({
    url: '/my/cate/list'
  })
}

/**
 * 添加-文章的分类
 * @param cate_name  cate_name:文章分类名
 * @param cate_alias  cate_alias:文章分类别名
 * @returns {*}
 */
const addArtCateAPI = ({cate_name, cate_alias}) => {
  return request({
    url: 'my/cate/add',
    method: 'POST',
    data: {
      cate_name,
      cate_alias
    }
  })
}

/**
 * 更新-文章分类
 * @param id  文章分类id
 * @param cate_name  文章分类名
 * @param cate_alias  文章分类别名
 * @returns {*}
 */
const updateArtCateAPI = ({id, cate_name, cate_alias}) => {
  return request({
    url: '/my/cate/info',
    method: 'put',
    data: {
      id,
      cate_name,
      cate_alias
    }
  })
}

/**
 * 删除-文章的分类
 * @param id 文章分类的id
 * @returns {*}
 */
const delArtCateAPI = (id) => {
  return request({
    url: `/my/cate/del`, // 参数在url/后面,后端得通过 req.params.id 获取传过来的参数
    method: 'delete',
    params: { // 参数在?后面，后端得通过 req.query.id 获取传过来的参数
      id
    }
  })
}

/**
 * 发布文章
 * @param formData FormData 表单数据对象
 * @returns {*} Peromise 对象
 */
const addArticleAPI = (formData) => {
  return request({
    url: '/my/article/add',
    method: 'post',
    // 如果是一个普通对象，axios会把它转成]SON字符串在请求里体里交给后台
    // 这个接口文档要求请求体里是一个FormData类型(表单数据对象)携带文件给后台
    data: formData
  })
}


const updateArticleAPI = (formData) => {
  return request({
    url: '/my/article/update',
    method: 'post',
    // 如果是一个普通对象，axios会把它转成]SON字符串在请求里体里交给后台
    // 这个接口文档要求请求体里是一个FormData类型(表单数据对象)携带文件给后台
    data: formData
  })
}

/**
 * 获取-文章列表
 * @param pagenum 当前页码数
 * @param pagesize 当前页面需要的数据条数
 * @param cate_id 文章分类id(注意不是文章id)
 * @param state 文章状态("已发布"和"草稿")2种值
 * @returns {*} Promise 对象
 */
const getArtListAPI = ({pagenum, pagesize, cate_id, state}) => {
  return request({
    url: '/my/article/list',
    params: {
      pagenum,
      pagesize,
      cate_id,
      state
    }
  })
}

/**
 * 获取-文章详情
 * @param id 文章id
 * @returns {*}
 */
const getArtDetailAPI = (id) => {
  return request({
    url: '/my/article/info',
    params: {
      id
    }
  })
}

/**
 * 删除-文章
 * @param id 文章id
 * @returns {*}
 */
const delArticleAPI = (id) => {
  return request({
    url: '/my/article/info',
    method: 'delete',
    params: {
      id
    }
  })
}

// 导出接口方法，为了在逻辑页面引入后调用
export {
  loginAPI, // 登入接口
  registerAPI, // 注册接口
  updatePwdAPI, // 更新-用户密码
  addArtCateAPI, // 添加-文章的分类
  delArtCateAPI, // 删除-文章的分类
  getArtListAPI, // 获取-文章列表
  addArticleAPI, // 发布文章
  delArticleAPI, // 删除-文章
  getUserInfoAPI, // 获取-用户信息
  getMenusListAPI, // 获取-侧边栏的数据
  getArtDetailAPI, // 获取-文章详情
  updateArtCateAPI, // 更新-文章分类
  updateArticleAPI,  // 更新-文章详情
  updateUserInfoAPI, // 更新-用户基本资料
  getArtCateListAPI, // 获取-文章分类
  updateUserAvatarAPI // 更新-用户头像
}
