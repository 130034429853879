import Vue from 'vue'
import VueRouter from 'vue-router'
// js文件无法直接访问到store
import store from '@/store'
// js文件无法通过this.$message使用
import { Message } from 'element-ui'

Vue.use(VueRouter)

const routes = [
  {
    path: '/', // 默认打开直接看到布局页
    component: () => import('@/views/layout'),
    redirect: 'home',
    children: [
      { path: 'home', component: () => import('@/views/home') },
      { path: 'user-info', component: () => import('@/views/User/userInfo') },
      { path: 'user-avatar', component: () => import('@/views/User/userAvatar') },
      { path: 'user-pwd', component: () => import('@/views/User/userPwd') },
      { path: 'art-cate', component: () => import('@/views/article/artCate') },
      { path: 'art-list', component: () => import('@/views/article/artList') }
    ]
  },
  {
    // webpack 提供 import 函数来路由懒加载导入组件
    // 路由懒加载，就是页面路由路径切换到 /reg，才去加载对应组件代码
    // 好处: 让首页加载文件体积更新，打开更快
    path: '/login', component: () => import('@/views/login')
  },
  {
    path: '/reg', component: () => import('@/views/register')
  }
]

const router = new VueRouter({
  routes
})

// 白名单，(无需登录可以访问的路由地址)
const whiteList = ['/login', '/reg']

// 全局前置路由守卫
// 浏览器第一次打开项目页面，会触发一次全局前置路由守卫函数
// 判断登陆与否:有token就证明登录了，无token未登录
// next() 如果强制切换路由地址，会再次走路由守卫再次去匹配路由表
router.beforeEach((to, from, next) => {
  // console.log(to, from)
  const token = store.state.token
  if (token) { // 登入了
    if (!store.state.userInfo.user) {
      // 在本地有token值，才去请求用户信息
      store.dispatch('getUserInfoActions')
    }
    next()
  } else { // 未登录
    // 数组.includes(值)，作用：判断值是否在数组里出现过，出现过原地返回 true
    if (whiteList.includes(to.path)) {
      // 未登录，可以访问的路由地址，则放行(路由全局前置守卫不会再次触发了，而是匹配路由表，让组件挂载)
      next()
    } else {
      Message({
        message: '请登录或注册!',
        type: 'error'
      })
      // next()强制切换到登录路径上
      next('/login')
    }
  }
})

export default router

// 退出登录，重新登录，只走相关组件代码（异步 dom 切换，不会导致所有代码重新执行，App.vue 不走)
// 效果不对:你换个账号它得重新请求用户数据呀
// 解决:
// 1．可以在登录页面,登录成功后,再发请求去拿到用户信息
// 2．可以在全局前置路由守卫中，写（路由跳转的时候，判断+获取)
