<template>
  <!-- 一级路由规则对象组件的挂载点 -->
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="less">
</style>
