import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { getUserInfoAPI } from '@/api'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: '', // 用来存储登录成功之后，得到的 token
    userInfo: {} // 保存用户信息(id, username, nickname, email, user_pic)
  },
  getters: {
    nickname: state => state.userInfo.nickname, // 昵称
    username: state => state.userInfo.username, // 用户名
    user_pic: state => state.userInfo.user_pic // 用户头像
  },
  mutations: {
    // 更新或保存 token
    updateToken (state, newToken) {
      state.token = newToken
    },
    // 更新用户的信息
    updateUserInfo (state, info) {
      state.userInfo = info
    }
  },
  actions: {
    // 定义初始化用户基本信息的 action 函数
    async getUserInfoActions (store) {
      const { data: res } = await getUserInfoAPI()
      // console.log(res)
      if (res.code === 0) {
        store.commit('updateUserInfo', res.data)
      }
    }
  },
  modules: {},
  // 配置为 vuex 的插件
  plugins: [
    createPersistedState() // 注入持久化插件
  ]

})

// vuex 默认保存在内存中,所以刷新所有的值会回归初始化(无法做到持久存储)
// 借助 npm install vuex-persistedstate@3.2.1 这个包可以让 vuex 做持久化存储
